import axios, { AxiosInstance } from 'axios';
import { useAuth } from '@clerk/clerk-react';

export const axiosEtsyInstance: AxiosInstance = axios.create({
  baseURL: 'https://api-staging.etseo.com/service/etseo-rest-staging/',
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
});
export const useAxiosInterceptors = () => {
  const { getToken } = useAuth();

  axiosEtsyInstance.interceptors.request.use(
    async config => {
      const token = await getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

export const useAxiosStripeInterceptors = () => {
  const { getToken } = useAuth();

  axiosStripeInstance.interceptors.request.use(
    async (config: any) => {
      const token = await getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

export const axiosStripeInstance = axios.create({
  baseURL: 'https://api-staging.etseo.com/service/etseo-webhook-staging/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
